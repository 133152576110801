import React from 'react';

export default function FourOhFour() {
  return (
    <>
      <h1>Page not found.</h1>
      <p>Oops, there's nothing here.</p>
    </>
  );
}
